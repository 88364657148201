<template>
  <div>
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
    <!-- Module Object for which discussion is to be started -->
    <div>
      <div v-if="propTopicObj.content_type == 'html'" v-html="propTopicObj.content"></div>
      <div v-else>
        {{propTopicObj.content}}
      </div>
    </div><!-- Module Object for which discussion is to be started -->

    <!-- Question Editor -->
    <div style="width:100%">
      <b-form class="comment-text d-flex align-items-center mt-1" action="javascript:void(0);" style="background:#f4f4f4; width:100%">
        <vue-editor
          :resizable="true"
          :style="[{width: '100%'}]"
          v-model="vmParentThreadQues"
          :editorToolbar="customToolbar"
          :autofocus="true"
          @keyup.enter="submitQuery(vmParentThreadQues)"
          placeholder="Ask a doubt..."
        />
        <div class="comment-attagement d-flex">
          <b-link href="javascript:void(0);">
            <i class="ri-send-plane-line mr-2" @click="submitQuery()"></i>
          </b-link>
        </div>
      </b-form>
    </div><!-- Question Editor -->

    <div v-for="(query, index) of threadObjList" :key="index+query.question_id">
      <!-- Question -->
      <ul class="d-flex justify-content-start mt-2 rounded p-2">
        <!-- User Image Avatar -->
        <div class="">
          <b-avatar class="logo-icon" :variant="getBgColorForLetter(query.user_questioned)" :text="getFirstLetterOfAString(query.user_questioned)" >
          </b-avatar>
        </div><!-- User Image Avatar -->

        <li class="d-flex flex-column ml-2 py-1 px-2 rounded commentBox"  style="background-color:#f0efef;width:100%">
          <h6 @click="viewProfile()">
            {{query.user_questioned}}
          </h6>
          <div class="mb-0">
            <span v-html="query.question" v-if="showUpdateParentQuestion !== query.question_id"></span>
            <!-- parent question editor -->
            <div v-if="showUpdateParentQuestion == query.question_id" class="p-2" style="margin-bottom: 5rem !important;">
              <vue-editor v-model="query.question"></vue-editor>
              <button class="btn btn-primary d-block pull-right add_button mt-1" @click="editPost({post_id: query.question_id, post_desc: query.question})">Update</button>
            </div><!-- parent question editor -->
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center comment-activity">
            <span>{{getFormatttedTimeAgo(query.query_time)}}</span>
            <b-link href="javascript:void(0);" @click="toggleAnsEditor(query.question_id)">Reply</b-link>
          </div>
        </li>
        <!-- Query Edit, Delete Dropdown -->
        <div v-if="1" class="d-flex flex-col  justify-content-center align-items-center ml-3 dropDown_ED">
          <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0 mr-0">
            <template v-slot:button-content>
              <b-link href="javascript:void(0)" class="text-secondary postThreeDotIcon"><i class="ml-0 ri-more-2-fill font-size-20"></i></b-link>
            </template>
            <a class="dropdown-item p-3" href="javascript:void(0)">
              <div class="d-flex align-items-top">
                <div class="icon font-size-20"><i class="fa-solid fa-pen-to-square"></i></div>
                <div class="data ml-2" @click="showUpdateParentQuestion=query.question_id"><h6>Edit Note</h6></div>
              </div>
            </a>
            <a class="dropdown-item p-3" href="javascript:void(0)">
              <div class="d-flex align-items-top">
                <div class="icon font-size-20"><i class="fa-solid fa-trash"></i></div>
                <div class="data ml-2" @click="deletePost(query.question_id)"><h6>Delete Note</h6></div>
              </div>
            </a>
          </b-dropdown>
        </div><!-- Query Edit, Delete Dropdown -->
      </ul><!-- Question -->

      <!-- Answer Editor -->
      <div class="mb-2" style="margin-left:5%" v-if="isReplyEditorOpen == query.question_id">
        <b-form class="comment-text d-flex align-items-center mt-1" action="javascript:void(0);" style="background:#f4f4f4; width:100%">
          <vue-editor
            :resizable="true"
            :style="[{width: '100%'}]"
            v-model="vmChildThreadComment"
            :editorToolbar="customToolbar"
            :autofocus="true"
            @keyup.enter="submitAnswer(vmParentThreadQues)"
            placeholder="Post an answer..."
          />
          <div class="comment-attagement d-flex">
            <b-link href="javascript:void(0);">
              <i class="ri-send-plane-line mr-2" @click="postAnswer(query.question_id)"></i>
            </b-link>
          </div>
        </b-form>
      </div><!-- Answer Editor -->
      <!-- Answers -->
      <div v-if="query.answer">
        <div style="width: 70%; display:flex;margin-left:5%" v-for="(ans, index) of query.answer.split('|')" :key="index">
          <!-- User Image Avatar -->
          <div>
            <b-avatar class="logo-icon" :variant="getBgColorForLetter(ans.split('\`~\`')[3])" :text="getFirstLetterOfAString(ans.split('\`~\`')[3])" >
            </b-avatar>
          </div><!-- User Image Avatar -->
          <div class="ml-2 mb-3 py-1 px-2 rounded" style="background-color:#f0efef;">
            <h6>
              {{ans.split('\`~\`')[3]}}
            </h6>
            <span v-if="showUpdateReply !== ans.split('\`~\`')[0]" v-html="ans.split('\`~\`')[1]"></span>

            <!-- Reply editor -->
            <div v-else class="p-2" style="margin-bottom: 5rem !important;">
              <vue-editor v-model="updatedReply"></vue-editor>
              <button class="btn btn-primary d-block pull-right add_button mt-1" @click="editPost({post_id: ans.split('\`~\`')[0], post_desc: updatedReply })">Update</button>
            </div><!-- Reply editor -->

            <span class="mt-0 comment-activity">
              <span>{{getFormatttedTimeAgo(ans.split('\`~\`')[4])}}</span>
            </span>
          </div>
          <!-- Reply Edit, Delete Dropdown -->
          <div class="d-flex flex-col  justify-content-center align-items-center ml-3 dropDown_ED">
            <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0 mr-0">
              <template v-slot:button-content>
                <b-link href="javascript:void(0)" class="text-secondary postThreeDotIcon"><i class="ml-0 ri-more-2-fill font-size-20"></i></b-link>
              </template>
              <a class="dropdown-item p-3" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-pen-to-square"></i></div>
                  <div class="data ml-2" @click="openReplyEditor(ans.split('\`~\`')[1], ans.split('\`~\`')[0])"><h6>Edit Reply</h6></div>
                </div>
              </a>
              <a class="dropdown-item p-3" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-trash"></i></div>
                  <div class="data ml-2" @click="deletePost(ans.split('\`~\`')[0])"><h6>Delete Reply</h6></div>
                </div>
              </a>
            </b-dropdown>
          </div><!-- Reply Edit, Delete Dropdown -->
        </div><!-- Answers -->
      </div>
    </div>
    <div v-if="threadObjList.length == 0">
      Start the thread by asking a query.
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor"
import { Posts } from "../FackApi/api/post"
import { DiscussionThread } from "../FackApi/api/DiscussionThread.js"
import Utility from "../Utils/utility"
import { QuizBanks } from "../FackApi/api/quizBank"

export default {
  name: "DiscussionThread",
  mounted () {
    this.loadModuleObject()
    this.getDiscussuionThreadList()
  },
  data () {
    return {
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        ["clean"] // remove formatting button
      ],
      vmParentThreadQues: "",
      showToast: false,
      toastTitle: "Discussion Thread",
      toastVariant: "default",
      toastMsg: "",
      threadObjList: [],
      isReplyEditorOpen: false,
      vmChildThreadComment: "",
      questionObj: null,
      showUpdateParentQuestion: false,
      showUpdateReply: null,
      updatedReply: ""
    }
  },
  props: {
    propTopicObj: {
      default: () => {},
      type: Object
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  components: {
    VueEditor
  },
  methods: {
    /**
     * submitQuestion
     */
    async submitQuery () {
      try {
        const queryObj = {
          user_id: this.userData.user_id,
          post_desc: this.vmParentThreadQues,
          post_type: 7, // Test Prep Query
          post_module_obj_id: this.propTopicObj.module_obj_id,
          user_type: 5,
          user_module_obj_id: this.userData.user_id,
          app_id: this.$route.params.appId,
          is_query: true
        }
        const queryAddResp = await Posts.postAdd(this, queryObj)
        if (!queryAddResp.resp_status) {
          this.toastVariant = "danger"
          this.toastMsg = "Unable to add the query"
          this.showToast = true
          return
        }
        this.vmParentThreadQues = ""
        this.getDiscussuionThreadList()
      }
      catch (error) {
        console.error("Exception in submitQuery and error: ", error)
      }
    },
    /**
     * postAnswer
     */
    async postAnswer (questionId) {
      try {
        const answerObj = {
          user_id: this.userData.user_id,
          post_desc: this.vmChildThreadComment,
          post_type: 7,
          post_module_obj_id: questionId,
          user_type: 5,
          user_module_obj_id: this.userData.user_id,
          app_id: this.$route.params.appId,
          is_query: false
        }
        const ansAddResp = await Posts.postAdd(this, answerObj)
        if (!ansAddResp.resp_status) {
          this.toastVariant = "danger"
          this.toastMsg = "Unable to add the query"
          this.showToast = true
          return
        }
        this.isReplyEditorOpen = null
        this.vmChildThreadComment = ""
        this.getDiscussuionThreadList()
      }
      catch (error) {
        console.error("Exception in postAnswer and error: ", error)
      }
    },
    /**
     * getDiscussuionThreadList
     */
    async getDiscussuionThreadList () {
      try {
        const discussionThreadList = await DiscussionThread.DiscussionThreadList(this, this.propTopicObj.module_obj_id)
        if (!discussionThreadList.resp_status) {
          return
        }

        this.threadObjList = discussionThreadList.resp_data.data
      }
      catch (error) {
        console.error("Exception in getDiscussuionThreadList and error: ", error)
      }
    },
    /**
     * toggleAnsEditor
     */
    toggleAnsEditor (questionId) {
      if (!this.isReplyEditorOpen) {
        this.isReplyEditorOpen = questionId
      }
      else {
        this.isReplyEditorOpen = null
      }
    },
    /**
     * getBgColorForLetter
     */
    getBgColorForLetter (name) {
      let firstLetter = this.getFirstLetterOfAString(name)
      return Utility.getBgColorForLetter(this, firstLetter)
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * getFormatttedTimeAgo()
     */
    getFormatttedTimeAgo (dateTime) {
      return Utility.getFormatttedTimeAgo(dateTime)
    },

    /**
     * loadModuleObject
     */
    async loadModuleObject () {
      try {
        switch (this.propTopicObj.module_name) {
          case "testseries":
            let qbkView = await QuizBanks.quizBankView(this, this.propTopicObj.module_obj_id)
            if (!qbkView.resp_status) {
              this.toastVariant = "danger"
              this.toastMsg = "Unable to load the question"
              this.showToast = true
              return
            }
            this.questionObj = qbkView.resp_data.data
            break
          default:
            console.error("Invalid module name")
        }
      }
      catch (err) {
        console.error("Exception in loadModuleObject and err: ", err)
      }
    },
    /**
     * getAnswerCategory
     */
    getAnswerCategory (ques) {
      let answerCategory = ""
      try {
        answerCategory = JSON.parse(ques.answer_category)[ques.user_response]
      }
      catch (e) {
        console.error("Exception is JSON Parsing answer_category", e)
      }
      return answerCategory
    },
    /**
     * editPost
     */
    async editPost (postObj) {
      try {
        const parentQuesEditObj = {
          post_id: postObj.post_id,
          post_desc: postObj.post_desc
        }
        let postEditResp = await Posts.postEdit(this, parentQuesEditObj)
        if (!postEditResp.resp_status) {
          this.toastVariant = "danger"
          this.toastMsg = "Unable to update the question"
          this.showToast = true
          return
        }
        this.showUpdateParentQuestion = null
        this.showUpdateReply = null

        this.toastVariant = "success"
        this.toastMsg = postEditResp.resp_msg
        this.showToast = true
        this.getDiscussuionThreadList()
      }
      catch (err) {
        console.error("Exception in editPost and err: ", err)
      }
    },
    /**
     * deletePost
     */
    async deletePost (postId) {
      try {
        const postDeleteResp = await Posts.postDelete(this, postId)
        if (!postDeleteResp.resp_status) {
          this.toastVariant = "danger"
          this.toastMsg = "Unable to delete the question"
          this.showToast = true
          return
        }
        this.showUpdateParentQuestion = null
        this.showUpdateReply = null

        this.toastVariant = "success"
        this.toastMsg = postDeleteResp.resp_msg
        this.showToast = true
        this.getDiscussuionThreadList()
      }
      catch (err) {
        console.error("Exception in deletePost and err: ", err)
      }
    },
    /**
     * openReplyEditor
     */
    openReplyEditor (oldReply, replyPostId) {
      this.updatedReply = oldReply
      this.showUpdateReply = replyPostId
    }
  }
}
</script>

<style>
  .comment-attagement{
    bottom: 2px !important;
    right: 2px !important;
  }
  .option_tag{
    text-transform: capitalize;
    font-weight: 600;
    margin-right: 10px;
    border: 1px solid var(--iq-body-text);
    padding: 1px 8px;
    border-radius: 30px;
    color: var(--iq-body-text);
    height: 30px;
    width: 29px;
    position: relative;
    top: -3px;
   }
   .bg_green {
    background-color: rgb(0, 128, 0, 0.5);
    border: 1px solid rgba(3, 68, 3, 0.5);
    color: #fff;
   }
  .dropDown_ED .dropdown-toggle::after{
    display: none;
   }
</style>
